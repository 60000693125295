import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [weight, setWeight] = useState('');
  const [yearsAB, setYearsAb] = useState('');
  const [goal, setGoal] = useState('maintain');
  const [results, setResults] = useState(null);

  const calculateMacros = () => {
    let pHigh = 0
    let pLow = 0
    let cHigh = 0
    let cLow = 0
    let fHigh = 0
    let fLow = 0

    switch (goal) {
        case 'gain':
            pHigh = 1.05
	    pLow = 0.95
	    cHigh = 2.10
	    cLow = 1.8
	    fHigh = 0.8
	    fLow = 0.6
        break;
	case 'lose':
	    pHigh = 0.9
	    pLow = 0.8
	    cHigh = 1.6
            cLow = 1.3
	    fHigh = 0.55
	    fLow = 0.45
        break;
        case 'maintain':
	    pHigh = 0.95
	    pLow = 0.9
	    cHigh = 1.8
	    cLow = 1.6
	    fHigh = 0.65
	    fLow = 0.5
	break;
    }
    let yearsAbCarbsMultiplier = 0;
    let yearsAbFatsMultiplier = 0;
    switch (true) {
        case (yearsAB >= 5):
	    yearsAbCarbsMultiplier = 1.27
            yearsAbFatsMultiplier = 1.16
	break;
        case (yearsAB == 4):
	    yearsAbCarbsMultiplier = 1.25
            yearsAbFatsMultiplier = 1.1
	break;
        case (yearsAB == 3):
	    yearsAbCarbsMultiplier = 1.2
            yearsAbFatsMultiplier = 1.06
	break;
        case (yearsAB == 2):
	    yearsAbCarbsMultiplier = 1.1
            yearsAbFatsMultiplier = 1.03
	break;
        case (yearsAB == 1):
	    yearsAbCarbsMultiplier = 1.05
            yearsAbFatsMultiplier = 1.01
	break;
	default:
	    yearsAbCarbsMultiplier = 1
	    yearsAbFatsMultiplier = 0.98
	break;
    }

    const lowEndProtein = Math.round(weight * pLow);
    const lowEndFat = Math.round(weight * fLow);
    const lowEndCarbs = Math.round(weight * cLow);
    const lowEndCalories = lowEndProtein*4 + lowEndCarbs*4 + lowEndFat*9
    const highEndProtein = Math.round(weight * pHigh);
    const highEndFat = Math.round(weight * fHigh * yearsAbFatsMultiplier);
    const highEndCarbs = Math.round(weight * cHigh * yearsAbCarbsMultiplier);
    const highEndCalories = highEndProtein*4 + highEndCarbs*4 + highEndFat*9
  
    setResults({
        highEnd: {
	    calories: highEndCalories,
	    protein: highEndProtein,
            fat: highEndFat,
            carbs: highEndCarbs,
        },
        lowEnd: {
	    calories: lowEndCalories,
	    protein: lowEndProtein,
            fat: lowEndFat,
            carbs: lowEndCarbs,
	}
    });
  };

  return (
    <Container className="mt-5 d-flex flex-row justify-content-center">
      <Row className="width-375 side-margin">
          <Card className="w-100 border-2 border-dark rounded">
            <Card.Body>
              <Card.Title className="text-center">Animal-Based Macro Calculator v2.3</Card.Title>
              <Form>
                <Form.Group className="mb-3" controlId="formWeight">
                  <Form.Label>Goal Body Weight (lbs)</Form.Label>
                  <Form.Control 
                    type="number" 
                    placeholder="Enter your goal body weight" 
                    value={weight} 
                    onChange={(e) => setWeight(e.target.value)} 
                  />
	         </Form.Group>
	         <Form.Group className="mb-3" controlId="formAB">
	  	  <Form.Label>Years PUFA-free (round down)</Form.Label>
	  	  <Form.Control
	            type="number"
		    step="1"
	            min="0"
	            placeholder="Years PUFA-free"
	            value={yearsAB}
	            onChange={(e) => setYearsAb(e.target.value)}
	          />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGoal">
                  <Form.Label>Goal</Form.Label>
                  <Form.Control 
                    as="select" 
                    value={goal} 
                    onChange={(e) => setGoal(e.target.value)}
                  >
                    <option value="maintain">Maintain weight</option>
                    <option value="gain">Gain muscle</option>
                    <option value="lose">Lose fat</option>
                  </Form.Control>
                </Form.Group>

                <Button variant="primary" onClick={calculateMacros}>
                  Calculate Macros
                </Button>
              </Form>

              {results && (
		<div className="d-flex flex-row justify-content-between">
			<div className="mt-4">
			  <h4>Results (low end)</h4>
			  <p>Protein: {results.lowEnd.protein} g/day</p>
			  <p>Fat: {results.lowEnd.fat} g/day</p>
			  <p>Carbohydrates: {results.lowEnd.carbs} g/day</p>
			  <p>Calories: {results.lowEnd.calories} kcal/day</p>
			</div>
			<div className="m-4">
			  <h4>Results (high end)</h4>
			  <p>Protein: {results.highEnd.protein} g/day</p>
			  <p>Fat: {results.highEnd.fat} g/day</p>
			  <p>Carbohydrates: {results.highEnd.carbs} g/day</p>
			  <p>Calories: {results.highEnd.calories} kcal/day</p>
			</div>
		</div>
              )}
            </Card.Body>
          </Card>
      </Row>
    </Container>
  );
}

export default App;

